import { useState } from "react";

type useBooleanReturn = [
  boolean,
  { setFalse(): void; setTrue(): void; toggle(): void }
];

export const useBoolean = (initialValue: boolean): useBooleanReturn => {
  const [value, setValue] = useState<boolean>(initialValue);
  const setFalse = () => setValue(false);
  const setTrue = () => setValue(true);
  const toggle = () => setValue(!value);
  return [value, { setFalse, setTrue, toggle }];
};

export default useBoolean;
